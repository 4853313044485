@import '../variables';

.alert {
  background-color: transparent;
  border-color: $lightgray;
  color: $darkgray;
  padding: 16px 16px;
  transition: transform .2s;
}

.alert-hover {
  &:hover {
    transform: scale(1.05);
    border-color: $blue;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
    h4{
      color: $blue;
    }
  }
}
