@import url('https://fonts.googleapis.com/css?family=Open+Sans:500,600,700,800,400italic,700italic,400');
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.6.3/css/font-awesome.min.css");
@import "variables";
@import "component/breakpoints";


:target::before {
  content: '';
  display: block;
  margin-top: -130px;
  height: 130px;
  width: 1px;
}

body {
  font-family: 'Open Sans', sans-serif;
  color: $black;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  text-transform: none;
  color:$black;
  letter-spacing: 0.5px;

  mark {
    background: rgba(21, 155, 255, 0.25);
  }

  .toc {
    background-color: transparent;
    padding: 24px;
    padding-top: 12px;
    margin: 0;
  }
}

.row {
  &:before {
    content: none;
  }

  &:after {
    content: '';
  }
}

a {
  color: $blue;

  &:hover,
  &:active {
    color: $darkgray;
  }
}

h1 {
  font-size: 40px;
  line-height: 48px;
  font-weight: 700;
  text-transform: none;
  text-align: left;
  color:$black;
  letter-spacing: -1.5px;
  padding: 24px 0;
}

h2 {
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
  text-transform: none;
  color:$black;
  letter-spacing: -0.5px;
}

h3 {
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
  text-transform: none;
  color:$black;
}

h4 {
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
  text-transform: none;
  color:$black;
}

subtitle {
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
  text-transform: none;
  color:$black;
  letter-spacing: 0.1px;
}



h5 {
  font-family: 'Roboto';
  font-style: normal;
  letter-spacing: 1.5px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  text-transform: uppercase;
  color:$black;
}

@media screen {
  pre {
    overflow: auto;

    > code {
      white-space: pre;
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
      text-transform: none;
      color:$black;
      letter-spacing: 0.25px;    
    }
  }
}

.container {
  width:100%;
}

#breadcrumb {
  padding: 0 32px;
}

.breadcrumb {
  white-space: inherit;
  font-size: 14px;
  line-height: 24px;
  font-weight:400;
  color:$black;
  padding: 16px 0;
  //padding: 0 16px 0 16px;
}

.body-content .article {
  margin-right: 0;
}

.sidefilter {
  @media only screen {
    @include media-breakpoint-up(md) {
      width: 24%;
    }
  }
  @include media-breakpoint-between(md, tablet){
    width: 30%;
  }
}

.article.grid-right {
  @media only screen {
    @include media-breakpoint-up(md) {
      margin-left: 26%;
    }
  }
  @include media-breakpoint-between(md, tablet){
    margin-left: 32%;
  }
}

.sidetoc {
  top: 180px;
  background-color: white !important;
  @media only screen {
    @include media-breakpoint-up(md) {
      width: 24%;
    }
  }
  @include media-breakpoint-between(md, tablet) {
    width: 30%;  
  }

  @include media-breakpoint-between(md, mobile) {
    border-left: 1px solid #e7e7e7;
    border-right: 1px solid #e7e7e7;
    position: fixed;
  }

  .nav > li {
    line-height: 24px;
    
    a {
      font-size: 14px;
      font-weight: 400;
      text-transform: none;
      color:$black;
      letter-spacing: 0.1px;
    }
  }

  .nav.level1 , .nav.level2 {
    > li {
      line-height: 20px;
        
      > a {
        font-size: 14px;
        font-weight: 400;
        text-transform: none;
        color:$black;
        letter-spacing: 0.25px;
      }
    }
  }

  .nav > li.active > a {
    color: $darkgray;
    font-weight: 600;
    
    &:last-child {
      border-radius: 6px;
      background-color: #F1F1F1;
      width: 100%;
    }

    &:hover {
      color: #000;
    }
  }

  .toc .nav .in ul li {
    margin-left: 9px;
  }

  .toc .nav > li.filtered > ul, .toc .nav > li.in > ul {
    display: block;
    @media only screen {
      @include media-breakpoint-down(md) {
        display: grid;
      }
    }
  }

  
  .toc li:after {
    @media only screen {
      @include media-breakpoint-down(md) {
        display: none;
      }
    }
  }

  .toc {
    .nav > li > .expand-stub::before {
      font-family: 'Glyphicons Halflings', 'ui-sans-serif';
      content: '\e258';
      font-size: 0.8em;
    }

    .nav > li.in > .expand-stub::before {
      font-family: 'Glyphicons Halflings', 'ui-sans-serif';
      display: inline-block;
      content: '\e258';
      font-size: 0.8em;
      transform: rotate(90deg);
    }

    .nav > li > .expand-stub + a {
      margin-left: 15px;
    }

    .nav > li > a {
      margin-left: 0;
      padding-left: 5px;
    }

    .nav > li > .expand-stub {
      left: 4px;
    }

    ul ul > li > a:before {
      content: '';
    }

    ul {
      padding-left: 5px;
      text-align: left;
      @media only screen {
        @include media-breakpoint-down(md) {
          text-align: left;
        }
      }
    }

    @media only screen {
      @include media-breakpoint-down(md) {
        margin-left: 0;
      }
      ul {
        margin: 0;

        li a {
          margin: 0;
        }
      }
    }
  }
}

.sidenav {
  background-color: #fff;
  border-bottom: 1px solid #e7e7e7;

  @media only screen {
    @include media-breakpoint-down(md) {
      position: fixed;
      width: 100%;
      z-index: 999;
    }
  }

  @include media-breakpoint-between(md, mobile) {
    background: transparent;
    padding: 0;
    margin: 0;
    border: none;
  }

}

.btn.toc-toggle.collapse {
  color: $darkgray;
  text-align: left;
  @include media-breakpoint-between(md, mobile) {
    display: none;
  }
}

.front-page {
  article section {
    margin-left: auto;
  }

  p {
    margin: 20px auto;
  }

  .subnav,
  .sideaffix,
  .footer {
    display: none;
  }


  .body-content {
    width: 100%;
    padding: 0;
    overflow: hidden;

    .article {
      margin: 0 !important;

      & > [class*='col'] {
        width: auto;
        padding: 0;
        float: none;
      }
    }
  }

  .button {
    display: inline-block;
    
    a {
      display: inline-block;
      padding: 7px 17px;
      font-size: 16px;
      line-height: 16px;
      font-weight: 700;
      box-shadow: 0 2px 4px 0 rgba(31, 31, 31, 0.25);
      border-radius: 8px;
      transition: color 0.2s ease-in-out,
      background-color 0.2s ease-in-out;
    }

    &.white a {
      background: #fff;
      color: $darkgray;

      &:hover {
        background: $purple;
        color: #fff;
      }
    }

    &.turquoise a {
      background: $purple;
      color: #fff;

      &:hover {
        background: darken($purple, 5);
      }
    }
  }

  .intro {
    padding: 100px 15px 60px;
    @media screen  {
      @include media-breakpoint-up(md) {
        padding: 200px 15px 120px;
      }
    }
    text-align: center;
    font-size: 18px;
    position: relative;

    h1 {
      font-size: 36px;
      @media screen  {
        @include media-breakpoint-up(md) {
          font-size: 44px;
        }
      }

      text-transform: none;
      font-weight: 600;
      width: auto;
      max-width: 100%;
      margin: 0 auto;
      letter-spacing: normal;
    }

    p {
      width: 100%;
      font-size: 22px;
    }

    strong {
      font-weight: bold;
    }

    .button a {
      margin-top: 20px;
    }
  }

  .headerboxes {
    display: flex;
    flex-wrap: wrap;
    padding: 40px 15px;
    @media screen  {
      @include media-breakpoint-up(md) {
        padding: 80px 15px;
      }
    }

    background-color: #f4f2f3;

    .headerbox {
      margin: 40px auto;

    }

    .headerbox-inner {
      padding: 0;
      height: 100%;
    }

    h2 {
      font-size: 30px;
      font-weight: 600;
      text-transform: none;

      .anchorjs-link {
        display: none;
      }
    }

    p {
      font-size: 16px;
      font-weight: 600;
    }

    .icon {
      margin: 0;
    }
  }
}

// Specific case for paragraphs under embedded videos
.embeddedvideo + p {
  margin-top: 10px;
}

// Algolia
.DocSearch-Hit-content-wrapper {
  height: 100%;
}

// Mermaid graph
/* Workaround until this issue https://github.com/mermaid-js/mermaid/issues/1984 is fixed and mermaid version updated */
.lang-mermaid svg {
  height: auto;
}

// For tabbed content
.tabGroup {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.tabGroup section[role="tabpanel"] > pre:last-child {
  margin-bottom: 0;
}

// Easy-copy-code
.code-header {
  box-sizing: content-box;
  background-color: #f2f2f2;
  color: #171717;
  display: flex;
  flex-direction: row;
  border: 1px solid #e3e3e3;
  border-bottom: 0;
  margin-top: 16px;
  min-height: 30px;
}
.tabGroup section[role="tabpanel"] > .codeHeader, .tabGroup section[role="tabpanel"] > pre {
  margin-left: 0;
  margin-right: 0;
}
.code-header > .language {
  padding: 2px 16px;
  flex-grow: 1;
  text-transform: uppercase;
  line-height: 26px;
}
.code-header > .action {
  position: relative;
  padding: 2px 10px;
  background-color: transparent;
  border: 0 solid #e3e3e3;
  border-left-width: 1px;
  color: #171717;
  cursor: pointer;
  display: flex;
  align-items: center;
  line-height: normal;
}
.code-header .glyphicon {
  margin-right: 0.25em;
}
.code-header > .action:hover {
  background-color: #fafafa;
}
.code-header + pre {
  margin-top: 0;
  border: 1px solid #e3e3e3;
}
.code-header > .action .successful-copy-alert {
  justify-content: center;
  align-items: center;
  right: 0;
  left: 0;
  bottom: 0;
  top: 0;
  position: absolute;
  display: flex;
  background-color: #128712;
  outline-color: #fff;
  color: #fff;
}
.code-header > .action .successful-copy-alert.is-transparent {
  opacity: 0;
  transition: 500ms opacity ease-in-out;
}

// This overrides the behavior from normalize.css
// which is added by the current version of bootstrap
// This should be removed once we update docfx and bootstrap
summary {
  display: list-item;
}
