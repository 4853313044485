@import "../variables";

:root {
  --docsearch-primary-color: #{$blue} !important;
  --docsearch-logo-color: #{$blue} !important;
}

.sidefilter {

  background-color: transparent;
  padding: 24px;
  height: auto;
  border: 1px solid #e7e7e7;
  border-top: 0;

  .DocSearch-Button {
    background: transparent;
    border: 2px inset $darkgray;
    border-radius: 6px;
    margin: 0;
    min-height: 45px;
    padding: 10px;
    width: 100%;
  }

  .DocSearch-Button:active, .DocSearch-Button:focus, .DocSearch-Button:hover {
    border: 2px solid $blue;
    box-shadow: none;
  }

  .DocSearch-Button .DocSearch-Search-Icon {
    display: none;
  }

  .DocSearch-Button-Placeholder {
    color: rgb(117, 117, 117);
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    line-height: 20px;
    padding: 10px;
    display: block;
  }
}

.DocSearch.DocSearch-Container {
  z-index: 9999;
}
