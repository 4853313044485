$blue: #159bff;
$lightblue: #EAF2F9;
$purple: #7a67f8;
$turquoise: #67e5ad;
$darkgray: #333333;
$lightgray: #f1f1f1;
$white: #fff;
$black: #242424;
$gray: #9d9d9d;
$offWhite: #fcfcfc;
$green: #67e5ad;
$red: #ed3f64;
$orange: #ff9c1a;


