@import "../variables";
@import "breakpoints";

/**
 * General notes:
 * This file is modifying the elementor menu as simply as it can. The selector are a bit hard to
 * understand because we cannot add class or ids on the menu.
 *
 * This is a modified version of the css in the header.scss file from the main website.
 * It was modified as little as possible, but they are quite different.
 *
 * Pixel are preferred as unit since the body font size is not the same as the website.
 * This causes relative units (em, rem) to behave differently.
 */

.visibility-opacity-transition {
  @include media-breakpoint-up(lg) {
    visibility: hidden;
    opacity: 0;
    transition: visibility 100ms, opacity 100ms ease-in-out;

    list-style: none;
  }
}

#header-container {
  padding: 16px 32px;
  z-index: 9999;
  background: #fff;

  display: flex;
  position: fixed;
  width: 100%;
  min-height: 67px;
  max-height: 67px;
  border-bottom: 1px solid #f4f2f3;
  justify-content: space-between;


  &::after,
  &::before {
    content: none;
  }

  #menu-menu-principal {
    float: right;
    list-style: none;

    @media (max-width: 1023px) {
      width: 100%;
      // 70vh is a magic value found by trial and error.
      max-height: 70vh;
      overflow-y: auto;
    }

    @include media-breakpoint-up(lg) {
      display: flex;
    }
  }


  #header-logo-container {
    width: 36px;
    vertical-align: middle;
    z-index: 9;


    img {
      width: 100%;
    }

    @include media-breakpoint-up(lg) {
      min-width: 120px;
    }
  }

  .navbar-toggle {
    padding: 0;
    margin: 10px 0;
    display: none !important;
    z-index: 9;

    .icon-bar {
      background: $darkgray;
      width: 20px;
    }

    .icon-bar + .icon-bar {
      margin-top: 3px;
    }

    @media (max-width: 1023px) {
      display: block !important;
    }
  }

  .navbar-brand {
    height: auto;
  }

  nav {
    a {
      padding: 10px;
      font-size: 13px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 14px;
      letter-spacing: normal;
      text-transform: uppercase;
      color: $black;

      &:hover,
      &:focus {
        color: $purple;
      }

      @include media-breakpoint-up(lg) {
        font-size: 19px;
        line-height: 17px;
      }
    }

    /**
     * Top level menu items
     */
    & > ul > li {

      @include media-breakpoint-up(lg) {
        margin: 0 4px;
        align-self: center;
        min-width: max-content;
      }

      @include media-breakpoint-up(navbar) {
        margin: 0 16px;
      }

      /**
       * menu-item-has-children means there is a submenu
       */
      &.menu-item-has-children {
        // actual submenu
        position: relative;

        & > a {

          &::after {
            display: inline-block;
            text-rendering: auto;
            -webkit-font-smoothing: antialiased;
            font: normal normal normal 10px/1 FontAwesome;
            content: "\f078";
            line-height: 12px;
            margin-left: 12px;
            vertical-align: top;

            @include media-breakpoint-up(lg) {
              line-height: 21px;
              font: normal normal normal 15px/1 FontAwesome;
            }
          }
        }

        & > ul {
          @extend .visibility-opacity-transition;
          width: 100%;

          @include media-breakpoint-up(lg) {
            width: max-content;
            display: flex;
            position: absolute;
            padding: 20px;
            top: 170% !important;
            left: -25% !important;

            flex-direction: column;

            box-shadow: 0 0 20px 2px rgba(36, 36, 36, 0.2);
            background-color: $white;
          }

          li {
            display: flex;
            border-bottom: 1px solid $lightblue;
            background: $white;

            @include media-breakpoint-up(lg) {
              border: none;
            }

            &.menu-item a {
              font-weight: 600;
              padding: 6px 0;
            }

            &.spacing-top a {
              margin-top: 15px;
            }

            &.column-title a {
              border-bottom: 1px solid #3A3A3A;
              font-weight: 700;
              border-radius: 0;
              margin-bottom: 8px;
              padding-bottom: 14px;
            }

            &.header-menu-category a {
              color: #7D7D7D;
              font-weight: 700;
              border-bottom: 1px solid #3A3A3A;
              cursor: unset;
              border-radius: 0;
              margin-bottom: 8px;
              padding-bottom: 14px;
              width:100%
            }

          }

          a {
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            text-transform: none;

            transition: 0s;
          }

          .column-title {
            flex-direction: column;

            & > a {
              font-weight: 700;
              line-height: normal !important;
            }

            ul {
              width: 192px;
              padding: 0;
            }
          }

          // sub-menu columns
          ul {
            @extend .visibility-opacity-transition;
            display: flex;
            position: inherit;

            flex-direction: column;

            a {
              border-left: 0;
            }
          }
        }

        // shows the submenu
        &:hover > ul {
          visibility: visible;
          opacity: 1;

          /**
           * This selector makes sure the submenu of a submenu appears under it
           * instead of on hover. This is to displays submenus as columns.
           */
          &:not([style*="display: none;"]) {
            .menu-item-has-children {
              ul {
                visibility: visible;
                opacity: 1;
              }
            }
          }
        }
      }

      /**
       * row-menu : the row menu class puts the first element of the menu in a row
       *            instead of a column (default). This makes it possible to have
       *            multiple columns side by side by add the row-menu class in wordpress.
       */
      &.row-menu > ul {
        flex-direction: row;
      }

    }
  }

  .get-started-btn {
    a {
      display: block;
      border: none;
      border-radius: 8px;

      margin-left: 0;
      padding: 8px 32px;

      font-size: 16px;
      line-height: 24px;
      font-weight: 700;
      min-width: 125px;
      text-align: center;
      text-transform: none;
      color: $black;

      background-color: $turquoise;
      background: linear-gradient(to top, $blue 50%, $turquoise 50%);
      background-size: 100% 200%;
      background-position: left top;
      transition: all 0.5s cubic-bezier(.69, .04, .9, .65);

      &:hover {
        color: $white;
        background-position: bottom left;
      }
    }
  }
}

#navbar {
  @media (max-width: 1023px) {

    overflow: auto !important;

    position: fixed;
    width: 100%;
    margin: 0;
    padding: 0;

    padding-top: 67px;
    left: 0;

    text-align: left;

    background: $lightblue;

    transition: height .4s ease .1s, opacity .6s ease;
    opacity: 0;

    &.collapse {
      height: 67px !important;
      display: none !important;
    }


    &.collapse.in {
      display: block !important;
      height: 100vh !important;
      opacity: 1;
    }

    .menu {
      display: block;
      margin: 0;
      padding: 0 0 10px 0;

      li {
        display: block;
        padding: 0;

        &.get-started-btn {
          position: absolute;
          padding: 0 16px;
          bottom: 10%;
          width: 100%;
        }

        &.menu-item-has-children {
          position: relative;

          ul {
            position: relative;
            max-height: 0;
            padding: 0;

            overflow: hidden;
            transition: all .5s ease;
          }

          &.open {
            ul {
              max-height: 1500px;
            }
          }
        }
      }

      a {
        display: block;
        padding: 10px 25px;
      }
    }
  }
}

.subnav {
  position: fixed;
  top: 67px;
  width: 100%;
  z-index: 999;
  background-color: #f8f8f8;
}
